.news {
  &-wrapper {
    p {
      margin-bottom: 0 !important;
    }
  }
  &-content {
    img {
      display: none;
    }
    * {
      font-size: inherit !important;
      font-weight: inherit !important;
      color: inherit !important;
    }
  }
}