.news-wrapper p {
  margin-bottom: 0 !important;
}

.news-content img {
  display: none;
}

.news-content * {
  font-size: inherit !important;
  font-weight: inherit !important;
  color: inherit !important;
}